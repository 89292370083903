import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { isSameDate, dateFromTime, formatCalendarDate, renderAppointmentDateTime, parseAppointmentDateTime } from '../../helpers/date';
import { setOrderInfo } from '../../actions/orderInfo';
import Calendar from 'react-calendar';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import SlideDialog from '../v2/SlideDialog';
import SkeletonLoader from '../SkeletonLoader';

function getAvailableDate(dateSlots) {
  return Object.keys(dateSlots);
}

function isAvailable(dateList, date) {
  const now = formatCalendarDate(new Date())
  const dateFormat = formatCalendarDate(date);
  if (dateList.includes(dateFormat) && dateFormat >= now) {
    return true;
  }
  return false
}

const getTileDisabled = (dateList) => ({ date, view }) => {
  if (view === 'month') {
    if (!isAvailable(dateList, date)) {
      return true;
    }
  }
}

const getTitleClassName = (dateList) => ({ date, view }) => {
  if (view === 'month') {
    if (isAvailable(dateList, date)) {
      return 'available';
    }
  }
  return '';
}

const getTileContent = (dateList) => ({ date, view }) => {
  if (view === 'month') {
    if (isAvailable(dateList, date)) {
      return <p style={{ margin: 0 }}>(ว่าง)</p>;
    }
  }
}

const useStyles = makeStyles(theme => ({
  calendarContainer: {
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

export default ({
  noNote,
  open,
  onClose = () => { },
  onConfirm = () => { },
  fetchTimeSlot = async () => { }
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = React.useState(null);
  const orderInfo = useSelector(state => state.orderInfo);
  const [dateTimeSlots, setDateTimeSlots] = React.useState({});
  const [dateList, setDateList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    fetch();
  }, []);

  let slotTimeList = dateTimeSlots[selectedDate] || [];
  if (isSameDate(new Date(selectedDate), new Date())) { // Prevent display time slot that has already passed.
    slotTimeList = slotTimeList.filter(val => {
      return dateFromTime(val.start_time) >= new Date();
    });
  }

  const fetch = async () => {
    setLoading(true)
    const dateTimeSlots = await fetchTimeSlot();
    setDateTimeSlots(dateTimeSlots);
    setDateList(getAvailableDate(dateTimeSlots));
    setLoading(false)
  }

  function confirmAppointment() {
    if (!selectedDate || !orderInfo.slotTimeId) {
      alert('กรุณาเลือกวันและเวลา');
      return;
    }
    onConfirm();
    setDateList([]);
    setDateTimeSlots({});
    setSelectedDate(null);
  }

  function onDateChange(date) {
    const dateFormat = formatCalendarDate(date);
    setSelectedDate(dateFormat);
    dispatch(setOrderInfo({ slotTimeId: null }));
  }

  function handleClose() {
    setSelectedDate();
    onClose();
  }

  function onSelect(e) {
    const slot = slotTimeList.find(val => `${val.id}` === `${e.target.value}`);
    if (slot) {
      dispatch(setOrderInfo({
        slotTimeId: slot.id,
        slotTimeText: renderAppointmentDateTime(parseAppointmentDateTime(slot.date, slot.start_time)),
      }))
    }
  }

  return (
    <SlideDialog
      open={open}
      primaryButtonText="ต่อไป"
      secondaryButtonText="ย้อนกลับ"
      onClickPrimary={confirmAppointment}
      onClickSecondary={handleClose}
    >
      <Box className={classes.calendarContainer}>
        {
          loading ? <SkeletonLoader /> : (
            <>
              <Calendar
                onChange={onDateChange}
                locale="th-th"
                tileDisabled={getTileDisabled(dateList)}
                tileClassName={getTitleClassName(dateList)}
                tileContent={getTileContent(dateList)}
              />
              <Select
                fullWidth
                native
                disabled={!selectedDate}
                value={orderInfo.slotTimeId}
                onChange={onSelect}
              >
                <option value="">เลือกเวลา</option>
                {
                  slotTimeList.map(val => (
                    <option key={val.id} value={val.id} disabled={val.reserved === true}>{val.start_time}</option>
                  ))
                }
              </Select>
              {
                noNote ? <></> : (
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="หมายเหตุ"
                    multiline
                    rows={3}
                    value={orderInfo.remark}
                    onChange={(e) => dispatch(setOrderInfo({ remark: e.target.value }))}
                  />
                )
              }
            </>
          )
        }
      </Box>
    </SlideDialog>
  )
}