import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from '../Button';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    height: 'auto',
    top: 'unset !important',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ({
  open,
  onClickPrimary,
  onClickSecondary,
  primaryButtonText = 'ตกลง',
  secondaryButtonText,
  children
}) => {
  const classes = useStyles();

  return (
    <Dialog className={classes.dialogRoot} fullScreen open={open} TransitionComponent={Transition}>
      <Box padding={2}>
        <Box mb={2} minHeight="250px" display="flex" justifyContent="center" alignItems="center">
          {children}
        </Box>
        <Divider />
        <Box height="75px" display="flex" justifyContent="center" alignItems="center">
          {
            secondaryButtonText && (
              <Box flex={1} marginRight={1}>
                <Button fullWidth variant="outlined" size="large" onClick={onClickSecondary}>{secondaryButtonText}</Button>
              </Box>
            )
          }
          <Box flex={1}>
            <Button fullWidth size="large" onClick={onClickPrimary}>{primaryButtonText}</Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
